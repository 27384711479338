import numeral from "numeral";
import { apiBaseUrl } from "lib/api";

import styles from "./listing-tile.module.scss";

const ListingTile = ({ id, title, price, bedroomCount, bathroomCount, images, listingUrl }) => {
    let mainImage = images[0] ?? null;

    for (let image of images) {
        if (image.main_image) {
            mainImage = image;
            break;
        }
    }

    return (
        <div className={styles.container}>
            <a href={listingUrl} className={styles.link}>
                <div className={styles.imageSlider}>
                    {(mainImage)
                        ? <img src={`${apiBaseUrl}/images/${mainImage.id}/raw?thumbnail_size=400`} alt="" />
                        : null
                    }
                </div>
                <div className={styles.keyDetails}>
                    <div className={styles.details}>
                        <div className={styles.price}>
                            £{numeral(price).format("0,0")}
                        </div>
                        <div className={styles.roomCounts}>
                            <div className={styles.bedroomCount}>
                                <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M176 288C220.1 288 256 252.1 256 208S220.1 128 176 128S96 163.9 96 208S131.9 288 176 288zM544 128H304C295.2 128 288 135.2 288 144V320H64V48C64 39.16 56.84 32 48 32h-32C7.163 32 0 39.16 0 48v416C0 472.8 7.163 480 16 480h32C56.84 480 64 472.8 64 464V416h512v48c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16V224C640 170.1 597 128 544 128z" />
                                </svg>
                                <span aria-label={`${bedroomCount} bedrooms`}>
                                    {bedroomCount}
                                </span>
                            </div>
                            <div className={styles.bathroomCount}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M32 384a95.4 95.4 0 0 0 32 71.09V496a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-16h256v16a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-40.91A95.4 95.4 0 0 0 480 384v-48H32Zm464-128H80V69.25a21.26 21.26 0 0 1 36.28-15l19.27 19.26c-13.13 29.88-7.61 59.11 8.62 79.73l-.17.17a16 16 0 0 0 0 22.59l11.31 11.31a16 16 0 0 0 22.63 0L283.31 81.94a16 16 0 0 0 0-22.63L272 48a16 16 0 0 0-22.62 0l-.17.17c-20.62-16.23-49.83-21.75-79.73-8.62l-19.26-19.27A69.25 69.25 0 0 0 32 69.25V256H16a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16Z" />
                                </svg>
                                <span aria-label={`${bathroomCount} bathrooms`}>
                                    {bathroomCount}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.title}>
                        {title}
                    </div>
                </div>
            </a>
        </div>
    );
}

export default ListingTile;
