import { Routes, Route } from "react-router-dom";
import { Home } from "pages/";
import { GlobalHeader } from "components/";

const App = () => (
    <>
        <GlobalHeader />
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/search" element={<div>search</div>} />
            <Route path="/listing" element={<div>listing</div>} />
            <Route path="*" element={<div>404</div>} />
        </Routes>
    </>
);

export default App;
