import axios from "axios";
import qs from "qs";
import { throttleAll } from "promise-throttle-all";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? "";

const api = axios.create({
    baseURL: apiBaseUrl,
});

const fetchAllPages = (response) => new Promise((resolve) => {
    const totalResults = response.data.total;
    const resultsPerPage = response.data.per_page;
    const currentPage = response.data.current_page;
    const lastPage = Math.ceil(totalResults / resultsPerPage);

    const [path, queryString] = response.config.url.split("?");
    const queryParams = qs.parse(queryString);

    const requestUrls = [];

    for (let page = 1; page <= lastPage; ++page) {
        // Skip the page we already have and fetch all others
        if (page === currentPage) {
            continue;
        }

        queryParams.page = page;

        requestUrls.push(`${path}?${qs.stringify(queryParams)}`);
    }

    const requests = requestUrls.map(
        (url) => () => api.get(url)
    );

    throttleAll(4, requests).then((results) => {
        const allData = results
            .map((result) => result.data.data)
            .flat(1)
            .concat(response.data.data);

        resolve(allData);
    });
});

export default api;
export { apiBaseUrl, fetchAllPages };
