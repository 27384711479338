import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "@uidotdev/usehooks";
import _ from "lodash";
import { ListingTile } from "components/";
import { Houses, Flats, Town, Rural, Coastal } from "components/category-links";
import { LoadingSpinner } from "components/loading-spinner";
import api from "lib/api";

import styles from "./home.module.scss";

const Home = () => {
    const [ isLoading, setLoading ] = useState(true);
    const [ listings, setListings ] = useState([]);
    const [ page, setPage ] = useState(1);
    const [ lastPageHit, setLastPageHit ] = useState(false);
    const [ ref, inView, ] = useInView();
    const windowSize = useWindowSize();

    useEffect(
        () => {
            //console.log(windowSize);
        },
        [windowSize],
    );

    useEffect(
        () => {
            setLoading(true);

            api.get(`/listings?status=for-sale&per_page=20&page=${page}`)
                .then((response) => {
                    setListings((existing) => (
                        _.uniqBy(
                            existing.concat(response.data.data),
                            (entry) => entry.id,
                        )
                    ));

                    if (response.data.last_page === page) {
                        setLastPageHit(true);
                    }

                    window.setTimeout(
                        () => setLoading(false),
                        100
                    );
                });
        },
        [page],
    );

    useEffect(
        () => {
            if (inView && !isLoading && !lastPageHit) {
                setPage((current) => current + 1);
            }
        },
        [inView, isLoading, lastPageHit],
    );

    return (
        <div className={styles.container}>
            <div className={styles.categoryLinks}>
                <Houses />
                <Flats />
                <Town />
                <Rural />
                <Coastal />
            </div>
            <div className={styles.listingContainer}>
                {listings.map((listing) => (
                    <ListingTile
                        key={listing.id}
                        id={listing.id}
                        title={listing.title}
                        price={listing.price}
                        bedroomCount={listing.bedroom_count}
                        bathroomCount={listing.bathroom_count}
                        images={listing.images}
                        listingUrl={listing.listing_url}
                    />
                ))}
            </div>
            <div ref={ref} />
            {(isLoading)
                ? <LoadingSpinner />
                : null
            }
        </div>
    );
};

export default Home;
