import styles from "./category-link.module.scss";

const Town = () => (
    <span className={styles.categoryLink}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <path d="M61 61V46a1 1 0 0 0-1-1h-1V6a1 1 0 0 0-1-1h-3V2a1 1 0 0 0-1-1H42a1 1 0 0 0-1 1v3h-3a1 1 0 0 0-1 1v17h-7a1 1 0 0 0-1 1v18h-2V23a1 1 0 0 0-1-1h-3.06A8 8 0 0 0 16 15.07V11h-2v4.07A8 8 0 0 0 7.06 22H4a1 1 0 0 0-1 1v38H1v2h62v-2ZM45 11v12h-2V11Zm2 13V11h2v34h-2Zm4 21V11h2v34Zm8 2v14H42V47Zm-4-2V11h2v34ZM42 7a1 1 0 0 0 1-1V3h10v3a1 1 0 0 0 1 1h3v2H39V7Zm-3 4h2v12h-2Zm-8 14h14v20h-4a1 1 0 0 0-1 1v15h-4V43a1 1 0 0 0-1-1h-4Zm3 36H18V44h16ZM15 17a6 6 0 0 1 6 6 1 1 0 0 0 1 1h3v12h-1.5a1.5 1.5 0 0 1-1.5-1.5 3.5 3.5 0 0 0-3.5-3.5h-3a1.5 1.5 0 0 1-1.5-1.5 3.5 3.5 0 0 0-3.5-3.5A1.5 1.5 0 0 1 9 24.5V23a6 6 0 0 1 6-6ZM5 24h2v.5a3.5 3.5 0 0 0 3.5 3.5 1.5 1.5 0 0 1 1.5 1.5 3.5 3.5 0 0 0 3.5 3.5h3a1.51 1.51 0 0 1 1.41 1H17a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.35a3.45 3.45 0 0 0 1.5.35H25v4h-8a1 1 0 0 0-1 1v18H5Zm15 12v2h-2v-2Z"/>
            <path d="M13 34H9a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Zm-1 4h-2v-2h2ZM13 42H9a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Zm-1 4h-2v-2h2ZM13 50H9a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Zm-1 4h-2v-2h2ZM21 46h2v4h-2zM25 46h2v4h-2zM29 46h2v4h-2zM21 52h2v4h-2zM25 52h2v4h-2zM29 52h2v4h-2zM44 49h13v2H44zM44 53h13v2H44zM44 57h13v2H44zM33 27h10v2H33zM33 31h10v2H33zM33 35h10v2H33zM33 39h10v2H33zM27 18a3 3 0 1 0-3-3 3 3 0 0 0 3 3Zm0-4a1 1 0 1 1-1 1 1 1 0 0 1 1-1ZM7 10a3 3 0 1 0-3-3 3 3 0 0 0 3 3Zm0-4a1 1 0 1 1-1 1 1 1 0 0 1 1-1Z"/>
        </svg>
        Town
    </span>
);

export default Town;
