import styles from "./category-link.module.scss";

const Rural = () => (
    <span className={styles.categoryLink}>
        <svg xmlns="http://www.w3.org/2000/svg" width="39.574" height="39.979">
            <path d="M18.174 28.041a.754.754 0 0 1-.415-.125c-2.957-1.969-4.576-4.285-4.811-6.883-.264-2.917 1.233-6.01 4.451-9.197-.042-.53-.084-1.02-.122-1.475-.479-5.646-.387-6.38 3.922-9.812a.74.74 0 0 1 .294-.143c5.863-1.386 9.804.761 12.045 6.559 1.711 1.167 3.418 2.464 4.268 4.229 1.128 2.344 1.273 4.668.42 6.722-.973 2.343-3.135 4.155-6.253 5.242a.75.75 0 1 1-.494-1.416c2.704-.942 4.558-2.464 5.362-4.401.685-1.648.551-3.549-.387-5.497-.721-1.498-2.281-2.639-3.954-3.771a.746.746 0 0 1-.282-.359C30.272 2.491 27.114.672 22.001 1.828c-3.691 2.945-3.67 3.207-3.229 8.406.047.556.099 1.165.15 1.834a.75.75 0 0 1-.228.598c-3.05 2.943-4.48 5.713-4.253 8.231.191 2.123 1.587 4.064 4.148 5.769a.75.75 0 0 1-.415 1.375z"/>
            <path d="M38.823 39.979a.746.746 0 0 1-.396-.114c-3.2-1.996-6.139-3.689-9.695-4.196-1.192-.17-2.294-1.975-2.499-3.095-.217-1.182-.174-2.387-.132-3.553.02-.573.041-1.146.029-1.715-.04-1.95-.196-3.943-.348-5.872-.357-4.569-.729-9.294.372-13.996a.753.753 0 0 1 .901-.56.752.752 0 0 1 .56.901c-1.048 4.476-.687 9.082-.338 13.537.153 1.949.312 3.965.353 5.958.012.597-.01 1.198-.03 1.799-.041 1.135-.079 2.208.107 3.229.145.787.915 1.808 1.239 1.88 3.839.547 7.051 2.397 10.273 4.409a.75.75 0 0 1-.396 1.388z"/>
            <path d="M30.216 29.239c-.926 0-1.923-.2-2.956-.602a.75.75 0 1 1 .543-1.398c1.85.719 3.493.658 4.63-.168 1.285-.936 1.914-2.79 1.771-5.219a.75.75 0 0 1 .705-.793.77.77 0 0 1 .793.704c.174 2.958-.673 5.273-2.386 6.521-.874.634-1.93.955-3.1.955zM23.98 34.495a.75.75 0 0 1-.748-.716l-.494-10.816c-2.696-.186-4.57-1.449-5.575-3.763a.75.75 0 0 1 1.375-.598c.855 1.968 2.417 2.885 4.914 2.885h.004c.4 0 .73.315.749.715l.525 11.508a.748.748 0 0 1-.715.783c-.011.002-.023.002-.035.002z"/>
            <path d="M.75 38.688a.75.75 0 0 1-.271-1.449l.569-.221c4.793-1.862 9.776-3.79 15.2-3.71 2.449.035 4.899.027 7.347.018 1.098-.004 2.194-.008 3.291-.008a.75.75 0 0 1 0 1.5c-1.095 0-2.19.004-3.285.008-2.458.01-4.915.018-7.374-.018-5.129-.058-9.762 1.716-14.636 3.609l-.569.221a.764.764 0 0 1-.272.05zM11.246 30.241c-.405 0-.81-.021-1.212-.069-2.249-.266-4.39-.99-6.279-1.629-.716-.242-1.389-.47-2.007-.649a.75.75 0 0 1-.342-1.228L6.58 21.04l-4.754-1.42a.752.752 0 0 1-.504-.934.746.746 0 0 1 .934-.504l5.903 1.763a.753.753 0 0 1 .337 1.227l-5.187 5.64c.3.099.608.204.926.311 1.819.615 3.882 1.313 5.975 1.561 1.721.201 3.606-.171 5.429-.532.59-.117 1.179-.233 1.762-.332 1.252-.212 2.522-.533 3.752-.842.563-.142 1.125-.283 1.689-.416a.75.75 0 1 1 .343 1.46c-.557.131-1.111.271-1.667.411-1.257.317-2.558.644-3.868.866-.569.097-1.144.21-1.72.325-1.538.304-3.118.617-4.684.617zM3.977 12.877a.75.75 0 0 1-.53-1.281c.463-.463.938-.916 1.415-1.368 1.04-.989 2.117-2.012 3.007-3.121.846-1.052 1.529-2.218 2.254-3.454.315-.539.632-1.078.961-1.605a.75.75 0 0 1 1.273.794 65.091 65.091 0 0 0-.94 1.57c-.72 1.228-1.464 2.497-2.378 3.634-.954 1.187-2.067 2.245-3.144 3.268-.467.444-.934.887-1.388 1.341a.74.74 0 0 1-.53.222z"/>
            <path d="M2.041 19.651a.75.75 0 0 1-.561-1.248c.849-.954 2.182-1.881 3.47-2.777.795-.552 1.607-1.117 2.272-1.668-.429-.138-.867-.282-1.297-.424-.788-.26-1.547-.511-2.157-.687a.752.752 0 0 1-.513-.929.748.748 0 0 1 .929-.513c.625.181 1.403.437 2.211.703a75.07 75.07 0 0 0 2.485.789.752.752 0 0 1 .353 1.219c-.831.936-2.15 1.853-3.427 2.74-1.216.848-2.474 1.723-3.204 2.544a.753.753 0 0 1-.561.251zM26.885 19.975a.749.749 0 0 1-.16-1.483c2.968-.653 5.854-1.746 6.86-5.281a.75.75 0 0 1 1.444.411c-1.227 4.304-4.723 5.617-7.981 6.334a.71.71 0 0 1-.163.019zM17.206 10.617a.748.748 0 0 1-.546-.236c-.902-.958-1.875-2.404-2.816-3.803-.91-1.351-1.85-2.748-2.67-3.618a.75.75 0 1 1 1.092-1.028c.904.959 1.879 2.408 2.822 3.809.907 1.349 1.847 2.743 2.664 3.612a.75.75 0 0 1-.546 1.264zM25.596 12.877a.75.75 0 0 1-.591-.287l-3.549-4.517a.75.75 0 0 1 1.18-.927l3.549 4.518a.75.75 0 0 1-.589 1.213zM10.429 35.14a.75.75 0 0 1-.747-.697l-.322-4.516a.75.75 0 0 1 1.496-.108l.322 4.517a.75.75 0 0 1-.749.804zM13.334 34.495a.75.75 0 0 1-.75-.75v-3.872a.75.75 0 0 1 1.5 0v3.872a.75.75 0 0 1-.75.75z"/>
        </svg>
        Rural
    </span>
);

export default Rural;
