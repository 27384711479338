import styles from "./category-link.module.scss";

const Flats = () => (
    <span className={styles.categoryLink}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <g fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="2">
                <path d="M2 58h60v4H2zM4 58V2h30v56M4 12h30M19 2v10M4 22h30M19 12v10M4 32h30M19 22v10" />
                <path d="M14 58v-5a5 5 0 0 1 5-5 5 5 0 0 1 5 5v5M7 9l4-4M12 9l4-4M27 9l4-4M27 19l4-4M27 29l4-4M22 9l4-4M22 19l4-4M22 29l4-4M7 29l4-4M12 29l4-4M4 42h30M19 32v10M27 39l4-4M22 39l4-4M7 39l4-4M12 39l4-4M7 19l4-4M12 19l4-4M34 22h24v36H34z" />
                <path d="M38 26h6v6h-6zM48 26h6v6h-6zM38 36h6v6h-6zM48 36h6v6h-6zM38 58V47h16v11M46 47v11M38 12h18v6H38zM40 18v4M54 18v4" />
            </g>
        </svg>
        Flats
    </span>
);

export default Flats;
