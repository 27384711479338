import styles from "./category-link.module.scss";

const Houses = () => (
    <span className={styles.categoryLink}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path d="M44.715 23.711a.975.975 0 0 1-1.381 0l-8.939-8.938a1.019 1.019 0 0 1-.17-.171l-3.83-3.829a1.019 1.019 0 0 1-.17-.171L24 4.377 4.667 23.711a.975.975 0 1 1-1.381-1.381L23.191 2.425c.031-.047.053-.101.094-.144A.975.975 0 0 1 24 2a.97.97 0 0 1 .715.281c.043.042.062.096.096.144L30 7.616V4.997a1 1 0 0 1 1-1h4c.277 0 .527.112.707.293a.993.993 0 0 1 .293.707v8.619l8.715 8.714c.381.381.381 1 0 1.381zM34 5.997h-2v3.619l2 2V5.997zm-24 16a1 1 0 0 1 1 1v19a2 2 0 0 0 2 2h6v-13a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h6a2 2 0 0 0 2-2v-19a1 1 0 1 1 2 0v19a4 4 0 0 1-4 4H13a4 4 0 0 1-4-4v-19a1 1 0 0 1 1-1zm17 21.999v-12h-6v12h6z" />
        </svg>
        Houses
    </span>
);

export default Houses;
