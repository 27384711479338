import styles from "./category-link.module.scss";

const Coastal = () => (
    <span className={styles.categoryLink}>
        <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 32 32">
            <path d="M15.015 4.285 4.188 15.978a1.5 1.5 0 0 0 1.101 2.519H15l.001 3H3.5c-.398 0-.779.158-1.061.439A1.395 1.395 0 0 0 2 22.997a1.499 1.499 0 0 0 1.031 1.424c.093.676.41 1.316.916 1.891.254.288.557.56.904.811-.18.018-.356.068-.522.151l-1.553.777a.5.5 0 1 0 .448.894l1.553-.777c.14-.07.306-.07.447 0l1.105.553c.423.211.92.211 1.342-.001l1.105-.552a.498.498 0 0 1 .448 0l1.105.553c.422.211.92.211 1.342 0l1.105-.552a.498.498 0 0 1 .448 0l1.105.552c.422.211.92.211 1.342 0l1.105-.553a.498.498 0 0 1 .448 0l1.105.553c.422.211.92.211 1.342 0l1.105-.553a.498.498 0 0 1 .448 0l1.105.553c.422.211.92.211 1.342 0l1.105-.553a.498.498 0 0 1 .448 0l1.105.552c.422.211.92.211 1.342 0l1.553-.777a.5.5 0 0 0-.448-.895l-.276.139v-2.776a1.502 1.502 0 0 0 1-1.414 1.5 1.5 0 0 0-1.5-1.5h-9.999l-.001-3h8.004a1.5 1.5 0 0 0 1.036-2.585L18 7.283V4.496a1.5 1.5 0 0 0-1.502-1.5l.001 1-.001-1c-.756 0-1.381.561-1.483 1.289ZM4.062 24.497c.137.563.502 1.083 1.024 1.538.707.617 1.697 1.11 2.859 1.43l.384-.192c.422-.211.92-.211 1.342 0l1.105.554c.141.07.307.07.448 0l1.105-.553c.422-.211.92-.211 1.342 0l1.105.553c.141.07.307.07.448 0l1.105-.553c.422-.212.92-.212 1.342 0l1.105.553c.141.07.307.07.448 0l1.105-.553c.422-.212.92-.212 1.342 0l1.105.553c.141.07.307.07.448 0l1.105-.553c.422-.212.92-.212 1.342-.001l1.105.553c.141.07.307.07.448 0l.276-.139v-3.19H4.062Zm23.938-2H3.5a.5.5 0 1 0 0 1H28a.5.5 0 0 0 0-1ZM17 7.485V4.496a.5.5 0 0 0-1 0v2.701l.001 10.8-.001.011.001 3.489h1L17 12.75V7.485Zm1 1.18 8.349 7.97a.5.5 0 0 1-.345.862h-8.003L18 12.746V8.665Zm-3-1.468v10.3H5.289a.5.5 0 0 1-.367-.84L15 5.773v1.424Z"/>
        </svg>
        Coastal
    </span>
);

export default Coastal;
