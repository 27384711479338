import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app";

import "./index.css";

const appEnvironment = process.env.REACT_APP_ENVIRONMENT ?? "production";

if (appEnvironment !== "development") {
    Sentry.init({
        dsn: "https://575a737158c0405892dcda3fd14da38d@crash.kuzem.net/4",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
