import styles from "./loading-spinner.module.scss";

const LoadingSpinner = () => (
    <div className={styles.container}>
        <div className={styles.spinner} />
        <span className={styles.label}>Loading…</span>
    </div>
);

export default LoadingSpinner;
